import logo from './logo.svg';
import './App.css';
import {Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';
import Ndoto from './Pages/Ndoto';
import Bobble from './Pages/Bobble';
import Login from './Pages/Login';


function App() {
  return (
   <>
    <Routes>
    <Route path='/login' element={<Login/>}/>

      <Route path='/' element={<Home/>}/>
      <Route path='/ndoto/:day' element={<Ndoto/>}/>
      <Route path='/bobble/:day' element={<Bobble/>}/>
    </Routes>
   </>
  );
}

export default App;
