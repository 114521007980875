import React, { useEffect } from 'react'
import classes from './Home.module.css'
import {useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie'

const Home = () => {
    const navigate = useNavigate();
    useEffect(()=>{
      const username = Cookies.get('username');
      console.log(username,"username")
     if(!username){
      navigate('/login');
     }else if (username === 'admin') {
      console.log('Admin user logged in');
     }else if (username === 'panz'){
      console.log('Panz user logged in');
      navigate('/login')
    }
    else {
      navigate('/login');
    }

    },[navigate])
  return (
    <div className={classes.container}>
    <div className={classes.sub_container}>
    <div className={classes.title}>
        Select Portal
    </div>
    <div className={classes.btns}>
        <button className={classes.btn2} onClick={()=>navigate('/ndoto/0')}>Ndoto</button>
        <button className={classes.btn2} onClick={()=>navigate('/bobble/0')}>Bobble</button>
    </div>
    </div>
    </div>
  )
}

export default Home
