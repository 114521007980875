import React,{useState,useEffect} from 'react'
import classes from './Ndoto.module.css'
import Layout from '../Layouts/Layout'
import axios from 'axios';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {useNavigate, useParams} from 'react-router-dom'
import Lottie from 'lottie-react'
import Loader from '../Animations/loader.json'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Cookies from 'js-cookie'

const Ndoto = () => {
  const [data, setData] = useState([]);
  const {day} = useParams()
  const [loading,setLoading] = useState(true);
  // const backend_url = 'http://localhost:7890'
  const backend_url = ''
  const navigate = useNavigate();
  useEffect(()=>{
    const username = Cookies.get('username');
    console.log(username,"username")
   if(!username){
    navigate('/login');
   }else if (username === 'admin') {
    console.log('Admin user logged in');
   }else if (username === 'panz'){
    console.log('Panz user logged in');
    navigate('/login')
  }
  else {
    navigate('/login');
  }

  },[navigate])

  const fetchData = async () => {
   try{
    setLoading(true)
    const res = await axios.post(`${backend_url}/sub/ndoto?day=${day}`);
    setLoading(false)
    setData(res.data.result);
    console.log(res.data.result)
    
   }
   catch(err){
    console.log(err)
    setLoading(false);
    toast.error(err.message)
   }
   setLoading(false)
    // console.log(res.data.message[0])
  };

  useEffect(() => {
    fetchData();
  }, [day]);



  return (
    <Layout>
      <div className={classes.container}>
       {
        !loading ?
        <DataTable
           value={data}
          stripedRows
          emptyMessage="No data found"
          responsive
          scrollable
          scrollHeight="500px"
          rows={10}
          paginator
          tableStyle={{ minWidth: "10rem" }}
          className={classes.tbl}
        >
          <Column field="svc_name" header="Service" className={classes.col}></Column>
          <Column field="SUBSCRIPTION_DATA" header="Subscriptions" className={classes.col}></Column>
          <Column field="BILLING_REN" header="Renewals" className={classes.col}></Column>
        </DataTable>
        :
        <Lottie
          animationData={Loader}
        />
       }
      </div>
      <ToastContainer/>
    </Layout>
  )
}

export default Ndoto
