import React, { useEffect, useState } from "react";
import classes from "./Bobble.module.css";
import Layout from "../Layouts/Layout";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {useNavigate, useParams} from 'react-router-dom'
import Lottie from 'lottie-react';
import Loader from '../Animations/loader.json'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { FilterMatchMode, FilterOperator } from 'primereact/api';
  import Cookies from 'js-cookie'


const Bobble = () => {
  // const backend_url = 'http://localhost:7890'
  const backend_url = ''
  const navigate = useNavigate();
  useEffect(()=>{
    const username = Cookies.get('username');
    console.log(username,"username")
   if(!username){
    navigate('/login');
   }else if (username === 'admin') {
    console.log('Admin user logged in');
   }else if (username === 'panz'){
    console.log('Panz user logged in');
   
  }
  else {
    navigate('/login');
  }

  },[navigate])

  const [data, setData] = useState([]);
  const {day} = useParams()
  const [loading,setLoading] = useState(true);
  const [MsisdnFilterValue,setMsisdnFilterValue] = useState('')

  const [filters, setFilters] = useState({
    msisdn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    svc: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    processDateTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    next_billing_date: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const onMsisdnFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;

  setFilters(_filters);
  setMsisdnFilterValue(value);
};




  const fetchData = async () => {
   
   try{
    setLoading(true)
    const res = await axios.post(`${backend_url}/sub/bobble?day=${day}`);
    setData(res.data.result);
    console.log(res.data.result)
    setLoading(false);

   }
   catch(err){
    console.log(err)
    setLoading(false);
    toast.error(err.message)
   }
  };

  useEffect(() => {
    fetchData();
  }, [day]);

  return (
    <Layout>
      <div className={classes.container}>
       {
        !loading ?
        <>
        <DataTable
           value={data}
          stripedRows
          emptyMessage="No data found"
          responsive
          scrollable
          scrollHeight="500px"
          rows={10}
          paginator
          tableStyle={{ minWidth: "10rem" }}
          className={classes.tbl}
        >
          <Column field="svc_name" header="Service" className={classes.col}></Column>
          <Column field="sub_dlr" header="Subscriptions" className={classes.col}></Column>
          <Column field="BILLING_REN" header="Renewals" className={classes.col}></Column>
        </DataTable>

      
</>
        :
        <Lottie
          animationData={Loader}
        />
       }
      </div>
      <ToastContainer/>
    </Layout>
  );
};

export default Bobble;
