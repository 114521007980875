import React from 'react'
import classes from './Layout.module.css'
import Header from '../Components/Header'

const Layout = ({children}) => {
  
  return (
    <div className={classes.container}>
    <Header/>
    <div className={classes.sub_container}>
    {children}
    </div>
    </div>
  )
}

export default Layout
